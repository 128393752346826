import React, {useState} from 'react';
import mlogo from './img/wynet.png';
import blogo from './img/blogo.png';
import YouTube from "react-youtube";
import './App.css';
import Modal from 'react-modal';
import "react-responsive-carousel/lib/styles/carousel.min.css"; 
import { Carousel } from 'react-responsive-carousel';

function App() {
  const [hamburger, setHamburger]= useState(1);
  const [index, setIndex] = useState(0);
  const [image, setImage] = useState("");
  const [photoIndex, setPhotoIndex] = useState(0)
  const opts = {
    height: "600px",
    width: "100%",
    playerVars: {
      rel: 0,
      autoplay: 0,
    },
  };
  const [modalIsOpen, setIsOpen] = React.useState(false);

  const customStyles = {
    content: {
      width: "80%",
      height: "80%",
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };

  const photo = [
    [
      "https://live.staticflickr.com/65535/53072106547_3961a85dae_b.jpg",
      "https://live.staticflickr.com/65535/53072106577_573e17f4f0_b.jpg",
      "https://live.staticflickr.com/65535/53072106537_f49b8df7d9_b.jpg",
      "https://live.staticflickr.com/65535/51355440576_3b476a7ec4_b.jpg",
      "https://live.staticflickr.com/65535/51356183859_cf04b56f2c_k.jpg",
      "https://live.staticflickr.com/65535/51354698022_d2ed43a582_b.jpg",
      "https://live.staticflickr.com/65535/51415670249_0c25d2308f_k.jpg", 
      "https://live.staticflickr.com/65535/52991464040_64bde2ace5_b.jpg",
      "https://live.staticflickr.com/65535/52991098616_7eae946229_b.jpg",
      "https://live.staticflickr.com/65535/52991098921_44712c5ce8_b.jpg",
      "https://live.staticflickr.com/65535/52991099026_e417ab2d44_b.jpg",
      "https://live.staticflickr.com/65535/52991558723_253d4ff5d9_b.jpg"

    ]
  ]

  const menu = [
    [
      {
        "name": "Süzme Mercimek Çorbası",
        "desc": "Tereyağlı, acısız biber sosla servis edilir",
        "name_en": "Lentil Soup",
        "desc_en": "Served with pepper sauce"
      },
       {
        "name": "Parmak / Elma Dilim Patates",
        "desc": "",
        "name_en": "French Fries / Potato wedges",
        "desc_en": ""
      },
       {
        "name": "Trüflü Parmesanlı Patates",
        "desc": "",
        "name_en": "Truffle parmesan potatoes",
        "desc_en": "Served with pepper sauce"
      },
       {
        "name": "Yoğurt",
        "desc": "Çömlekte Manda Yoğurdu",
        "name_en": "Yoghurt",
        "desc_en": "Buffalo yoghurt served in pot"
      },
       {
        "name": "Soğan Halkası",
        "desc": "",
        "name_en": "Onion Rings",
        "desc_en": ""
      },
    ],
      [
      {
        "name": "Mevsim Salata",
        "desc": "Akdeniz yeşillikleri, mor lahana turşusu, cherry domates, mısır, havuç, yağ limon sos ile servis edilir",
        "name_en": "Season Salad",
        "desc_en": " Mediterranean greens, purple cabbage pickies, corn, cherry tomato, carrot, olive oil and lemon sauce."
      },
       {
        "name": "Gavur Dağı",
        "desc": "Cevizli nar ekşili zeytinyağlı sos ile servis edilir.",
        "name_en": "Turkish Tomato Salad with Walnuts & Cumin",
        "desc_en": ""
      },
       {
        "name": "Çıtır Tavuklu Salata",
        "desc": "Akdeniz yeşillikleri, kroton ekmek, özel sezar sos, çıtır tavuk ve parmesan ile servis edilir.",
        "name_en": "Chicken Salad",
        "desc_en": "Mediterranean greens, crispy chicken, bread croutons, special caesar sauce and parmesan"
      },
       {
        "name": "Tulum Peynirli Salata",
        "desc": "Akdeniz yeşillikleri, yaban mersini, lolorosso, kibrit elma, ceviz, cherry domates, soya filizi, tulum peyniri, nar, nar ekşisi, zeytinyağlı sos ile servis edilir.",
        "name_en": "Goat Cheese Salad",
        "desc_en": "Mediterranean greens, blueberry, apple, lolorosso, cherry tomato, soybeon sprouts, walnut, goat cheese, pomegranate, olive oil sauce"
      },
       {
        "name": "Buhara Pilavı",
        "desc": "Nohut, kuşbaşı et, bezelye, havuç ve kapya biberi ile harmanlanmış olarak servis edilir",
        "name_en": "Buhara Rice",
        "desc_en": "Blended with chickpea, meat cubes, carrot and copla pepper"
      },
       {
        "name": "Izgarada Közlenmiş Kaşarlı Mantar",
        "desc": "Tereyağı ile servis edilir.",
        "name_en": "Grilled Mushroom With Cheese",
        "desc_en": ""
      },
       {
        "name": "Kasap Sucuk",
        "desc": "",
        "name_en": "Turkish Style Fermented Sausage",
        "desc_en": ""
      },
    ],
    
      [
      {
        "name": "Külbastı",
        "desc": " Közlenmiş domates ve biber ile servis edilir. (200-230 gram)",
        "name_en": "Grilled Cutlet",
        "desc_en": "Served with grilled pepper and tomato"
      },
       {
        "name": "Yağlı Kara",
        "desc": "Közlenmiş domates ve biber ile servis edilir. (200-230 gram)",
        "name_en": "Lamb Loin",
        "desc_en": "Served with grilled pepper and tomato"
      },
       {
        "name": "Dana Lokum",
        "desc": "Marine edilmiş köz patates, domates ve biber ile servis edilir. (200-230 gram)",
        "name_en": "Tenderized Veal Cutlets",
        "desc_en": "Served with marinated potato, tomato, and pepper"
      },
       {
        "name": "Antrikot",
        "desc": "Dağ kekiği, biberiye, hardal sosu ve beyaz pilav ile servis edilir. (200-230 gram)",
        "name_en": "Rib Steak",
        "desc_en": " Served with thyme, rosemary, mustard and white rice."
      },
       {
        "name": "Kuzu Pirzola",
        "desc": "Kekik ve közlenmiş domates ile servis edilir. (4 adet)",
        "name_en": "Lamb Chops",
        "desc_en": "Served with thyme and grilled tomato."
      },
       {
        "name": "Dana Bonfile",
        "desc": "Kremalı mantar sos ve hardal sos ile servis edilir. (200-230 gram)",
        "name_en": "Veal Steak Fillet",
        "desc_en": "Served with cream of mushroom sauce and mustard."
      },
       {
        "name": "Izgara Köfte",
        "desc": "Soğan, patates püresi, özel sos ve beyaz pilav ile servis edilir. (200 gram)",
        "name_en": "Grilled Meatballs",
        "desc_en": "Served with onion, potato puree, white rice and special sauce."
      },
      {
        "name": "Kaşarlı Köfte",
        "desc": " Közlenmiş domates, biber ve patates püresi ile servis edilir. (200 gram)",
        "name_en": "Grilled Meatballs with Cheese",
        "desc_en": "Served with grilled tomato, pepper and, potato puree."
      },
           {
        "name": "Kekikli Piliç Izgara (But)",
        "desc": "Közlenmiş domates ile servis edilir. (200-230 gram)",
        "name_en": "Grilled Chicken (containing thyme)",
        "desc_en": "Served with grilled tomato."
      },
           {
        "name": "Karışık Izgara",
        "desc": "(Pirzola, Yağlı Kara, Lokum, Tavuk Izgara) közlenmiş biber ve domates ile servis edilir. (300 gram)",
        "name_en": "Mixed Grill",
        "desc_en": "(Lamb chops, lamb loin, meatball, tenderized veal cutlets, grilled chicken) served with grilled pepper and tomato."
      },
    ],
    [
      {
        "name": "Double Cheddar Burger",
        "desc": "El yapımı özel ekmek, çift cheddar peyniri, karamelize soğan, kaju patates ve wyn sos ile servis edilir. (180 gram)",
        "name_en": "Double Cheddar Burger",
        "desc_en": "Handmade Hamburger Roll, double cheddar, caramelized onion, cajun french fries and wyn sauce."
      },
       {
        "name": "Smoke Burger",
        "desc": "El yapımı özel ekmek, cheddar peyniri, dana füme, karamelize soğan, trüf parmesan patates ve wyn sos ile servis edilir",
        "name_en": "Smoke Burge",
        "desc_en": "Served with handmade hamburger roll, cheddar, smoked beef, caramelized onion, truffle parmesan potatoes and wyn sauce."
      },
       {
        "name": "Spesiyel Keçi Peynirli Burger",
        "desc": "El yapımı özel ekmek, cheddar peyniri, lolorosso, kırmızı soğan marmelatı, sirke-kekik patates ve wyn sos ile servis edilir. (180 gram)",
        "name_en": "Special Burger (with goat cheese)",
        "desc_en": "Handmade hamburger roll, cheddar, lolorosso, red onion marmalade, vinegar- thyme french fries."
      },
       {
        "name": "Küşleme",
        "desc": "",
        "name_en": "Fatless and nerveless meat",
        "desc_en": ""
      },
       {
        "name": "Wyn-Et Köfte",
        "desc": "Kaşar ve üzerine bonfileye sarılarak servis edilir. (250 gram)",
        "name_en": "Wyn-Et Meatballs",
        "desc_en": "Meatballs wrapped in fillet steak with cheese"
      }, {
        "name": "Çoban Kavurma",
        "desc": "Kuşbaşı et, bahçe domatesi, sivri biber ve arpacık soğan tereyağı ile servis edilir.",
        "name_en": "Diced Lamb Fried On Hot Pot",
        "desc_en": "Blended with tomato, shallot, green pepper and butter"
      }
    ],
    [
      {
        "name": "Fıstıklı Sıcak İrmik",
        "desc": "",
        "name_en": "Hot Semolina Helva Blended With Peanut",
        "desc_en": ""
      },
       {
        "name": "Dondurmalı İrmik",
        "desc": "",
        "name_en": "Hot Semolina Helva With Ice Cream",
        "desc_en": ""
      }, {
        "name": "Fıstıklı Baklava",
        "desc": "",
        "name_en": "Baklawa",
        "desc_en": ""
      }
      ]
  ];

  function openModal(index, modalImage) {
    setIndex(index);
    setImage(modalImage);
    setIsOpen(true);
  }

  const openInNewTab = url => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  function closeModal() {
    setIsOpen(false);
  }

  function _onReady(event) {
    event.target.pauseVideo();
  }

  return (
    <div className="App">
            <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
      >
        <div className = "modal">
          <div className="modal-content">
          <img className="modal-header" src={image} />
          <div class="grid-container">
             {
               menu[index].map(item => {
                  return <div class="grid-item"><b>{item.name} </b>{item.desc}<br/> <i><b>{item.name_en} </b>{item.desc_en}</i> </div>
               })
          }
  
          </div>
          </div>
        </div>
      </Modal>
  <section id="header">
    <div className="header container">
      <div className="nav-bar">
        <div className="brand">
          <img href="#home" src={mlogo}></img>
        </div>
        <div className="nav-list">
          <div className={hamburger == 1 ? "hamburger":"hamburger active"} onClick={()=>{hamburger == 1 ? setHamburger(2): setHamburger(1)}}><div className="bar"></div></div>
          <ul className={hamburger == 1 ? "":"active"}>
            <li><a href="#home" data-after="ANASAYFA" onClick={()=>setHamburger(1)}>ANASAYFA</a></li>
            <li><a href="#photos" data-after="GALERİ" onClick={()=>setHamburger(1)}>GALERİ</a></li>
            <li><a href="#menu" data-after="WYN-ET / DÖNER MENÜ" onClick={()=>setHamburger(1)}>WYN-ET / DÖNER MENÜ</a></li>
            <li><a href="#about" data-after="HAKKIMIZDA" onClick={()=>setHamburger(1)}>HAKKIMIZDA</a></li>
            <li><a href="#contact" data-after="BİZE ULAŞIN" onClick={()=>setHamburger(1)}>BİZE ULAŞIN</a></li>
          </ul>
        </div>
      </div>
    </div>
  </section>
  <section id="home">
    <div className="home container">
      <div>
        <h1><span></span></h1>
        <h1>Eşsiz bir lezzET <span></span></h1>
        <h1>WYN-ET <span></span></h1>
        <a href="#about" type="button" className="cta">Biz Kimiz?</a>
      </div>
    </div>
  </section>
  <section id="photos">
    <div className="photos container">
      <div className="service-top">
        <h1 className="section-title">FOTO<span> GALERİ</span></h1>
         </div>

         <div className="carousel-style">
         <Carousel>
           {
             photo[photoIndex].map(item => {
               return <div>
               <img src={item} />
           </div>
             })
           }
            </Carousel>
         </div>
    </div>
  </section>
  <section id="menu">
    <div className="menu container">
      <div className="menu-header">
        <h1 className="section-title">MENÜ <span>İÇERİĞİ</span></h1>
      </div>
      <div className="all-menu">
        <div className="project-item" onClick={() => openInNewTab('https://wyn-et.com/doner-menu.pdf')}>
          <div className="project-info">
          <h1>WYN-ET Restorant olarak, yılların döner ustası olan Yunus Usta’nın özenle hazırladığı döner ve iskenderimiz , kendi fırınımızda hazırladığımız tombik ve tırnak pideler ile damaklarda iz bırakacak bir döner sunuyoruz. Lezzetli bir döner için WYN-ET'te buluşalım.</h1>
          </div>
          <div className="project-img">
            <img src="https://live.staticflickr.com/65535/52991464040_64bde2ace5_b.jpg" alt="img"/>
          </div>
        </div>
        <div className="project-item" onClick={() => openInNewTab('https://wyn-et.com/menu.pdf')}>
          <div className="project-info">
          <h1>Wyn-et şeflerimizin özenle hazırladığı birbirinden lezzetli yemekler ile dünya mutfağımız damaklarda tat bırakıyor</h1>
          </div>
          <div className="project-img">
            <img src="https://live.staticflickr.com/65535/52990509557_3ee6a52480_k.jpg" alt="img"/>
          </div>
        </div>
      </div>

      <h2 onClick={() => openInNewTab('https://wyn-et.com/doner-menu.pdf')}>Döner Menüsünü Görüntülemek İçin Lütfen Tıklayın -></h2>
      <h2 onClick={() => openInNewTab('https://wyn-et.com/menu.pdf')}>Dünya Mutfağı Menüsünü Görüntülemek İçin Lütfen Tıklayın -></h2>
    </div>
  </section>
  <section id="about">
    <div className="about container">
      <div className="col-right">
        <h1 className="section-title"> <span>HAK</span>KIMIZDA</h1>
        <p>Wyn-et Restaurant hikayesi Days Hotel by Wyndham İstanbul Maltepe bünyesinde başlayan bir serüven. Gerek Days Hotel by Wyndham’ın restoranımıza katmış olduğu hoş atmosfer gerekse restoranımızın kendine has mimari tasarımı ile büyüleyici bir stili olan Wyn-et Restaurant akıllarda kalıcı bir yemek deneyimi sunmak için özenle tasarlandı. Farklı masa tipleri, çarpıcı renkler ile donatılmış koltuklar ve özel iç tasarımı ile sizlere hizmet vermekten Wyn-et olarak kendimizi hem mutlu hem de heyecanlı hissediyoruz.
        Wyn-et Restorant olarak, özenle hazırladığımız döner ve iskenderimiz , kendi fırınımızda hazırladığımız tombik ve tırnak pideler ile damaklarda iz bırakacak bir döner sunuyoruz. Lezzetli ve kaliteli bir döner için adres Wyn-et. Döner servisimiz 12:00-16:00 saatleri arasındadır, diğer zaman dilimlerinde dünya mutfağı menümüz sunulmaktadır. Bizim için lezzetin tarifi sizlerin her bize misafir olduğunuzda karşılaştığınız aynı lezzet. Wyn-et ekibi olarak her zaman kendimizi daha da geliştirme amacı ile yola devam ediyoruz ve sizleri daha fazla memnun etme gayesi ile çalışmalarımızı sürdürüyoruz.
</p>
       
      </div>
    </div>
  </section>
  
  <section id="contact">
    <div className="contact container">
      <div><h1 className="section-title">Bize <span>Ulaşın</span></h1></div>
      <div className="contact-items">
        <div className="contact-item">
          <div className="icon"><img src="https://img.icons8.com/bubbles/100/000000/phone.png"/></div>
          <div className="contact-info">
            <h1>Telefon</h1>
            <h2>0216 755 20 20</h2>
            <h2></h2>
          </div>
        </div>
        <div className="contact-item">
          <div className="icon"><img src="https://img.icons8.com/bubbles/100/000000/new-post.png"/></div>
          <div className="contact-info">
            <h1>Email</h1>
            <h2>bilgi@wyn-et.com</h2>
          </div>
        </div>
        <a href="https://goo.gl/maps/9DZQvomr7VT6Kg99A" className="contact-item">
          <div className="icon"><img  href="https://goo.gl/maps/9DZQvomr7VT6Kg99A" src="https://img.icons8.com/bubbles/100/000000/map-marker.png"/></div>
          <div className="contact-info">
            <h1 href="https://goo.gl/maps/9DZQvomr7VT6Kg99A">Adres</h1>
            <h2 href="https://goo.gl/maps/9DZQvomr7VT6Kg99A">Gülsuyu Mahallesi E-5 Yanyol Maltepe/İSTANBUL <br/> (Konuma ulaşmak için tıklayınız.)</h2>
          </div>
        </a>
      </div>
    </div>
  </section>

  <section id="footer">
    <div className="footer container">
    <img className="brand" src={blogo}></img>
      <div className="social-icon">
        <div className="social-item">
          <a href="https://www.facebook.com/Wynet-Steak-Restaurant-101814678843216"><img src="https://img.icons8.com/bubbles/100/000000/facebook-new.png"/></a>
        </div>
        <div className="social-item">
          <a href="https://www.instagram.com/wynetrestaurant/?hl=tr"><img src="https://img.icons8.com/bubbles/100/000000/instagram-new.png"/></a>
        </div>
      </div>
    </div>
  </section>
    </div>
  );
}

export default App;
